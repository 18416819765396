.loader{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;    
}

.loader img{
    width: 110px;
}